import React, { useState, useRef, useEffect } from "react"
import Button, { ButtonProps } from "@/components/Button/button"
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

interface CustomRangeProps {
  className?: string
}

const CustomRange = ({
  className,
} : CustomRangeProps) => {

  const [state, setState] = useState({
    sliderVal: 2,
  })

  const handleChange = evt => {
    const name = evt.target.name;
    const value = evt.target.value;
    setState({
      ...state,
      [name]: value
    })
  }

  const [isMonthly, setIsMonthly] = useState(false)
  const [isQuarterly, setIsQuarterly] = useState(false)
  const [isYearly, setIsYearly] = useState(false)

/* HANDLE TOGGLE TRIPPLES */

  const handleMonthly = () => {
    setIsMonthly(prev => !prev)
    setIsQuarterly(false)
    setIsYearly(false)
  }
  const handleQuarterly = () => {
    setIsQuarterly(prev => !prev)
    setIsMonthly(false)
    setIsYearly(false)
  }
  const handleYearly = () => {
    setIsYearly(prev => !prev)
    setIsQuarterly(false)
    setIsMonthly(false)
  }

  /* SET DISCOUNTS HERE */

  let multiplier = 1;

  if (isQuarterly) {
    multiplier = 0.95
  }
  if (isYearly) {
    multiplier = 0.85}

  /* SPENT + EXPONENCIAL FUNCTION */
  let expRate = state.sliderVal * (state.sliderVal/1960);
  const valueSpend = Math.round(state.sliderVal * expRate) + 5;

  /* PRIZE GIVEN */
  let valueFee = Math.round(99 * multiplier);
  let valueExt = "";
  let dollarSign = "$";

  if (valueSpend > 20) {
    valueFee = 199 * multiplier;
    valueFee = Math.round(valueFee);
  }
  if (valueSpend > 60) {
    valueFee = 299 * multiplier;
    valueFee = Math.round(valueFee);
  }
  if (valueSpend > 100) {
    valueFee = 399 * multiplier;
    valueFee = Math.round(valueFee);
  }
  if (valueSpend > 250) {
    valueFee = 699 * multiplier;
    valueFee = Math.round(valueFee);
  }
  if (valueSpend > 499) {
    valueFee = "Custom";
    valueExt = "+"
    dollarSign = "";
  }

  return (
    <div>

    <div className="prizing__toggles">
        <a onClick={handleMonthly} className={`prizing__toggle ${multiplier === 1 ? "prizing__toggle-active" : ""}`}>
          <h3>Just try</h3>
          <p>Monthly plan from</p>
          <h2>$99
            <span> / month</span>
          </h2>
          <p>full price</p>
        </a>
        <a onClick={handleQuarterly} className={`prizing__toggle ${multiplier === 0.95 ? "prizing__toggle-active" : ""}`}>
          <h3>Quarter first</h3>
          <p>Quarterly plan from</p>
          <h2>$282
            <span> / season</span>
          </h2>
          <p>from $94 / month</p>
        <p className="prizing__save">Save 5% a month</p>
        </a>
        <a onClick={handleYearly} className={`prizing__toggle ${multiplier === 0.85 ? "prizing__toggle-active" : ""}`}>
          <h3>Fully trust</h3>
          <p>Annual plan from</p>
          <h2>$1008
            <span> / year</span>
          </h2>
          <p>from $84 / month</p>
          <p className="prizing__save">Save 15% a month</p>
        </a>
    </div>

    <div className="prizing__text">
      <div className="prizing__line"></div>
      <h2 data-sal="slide-up" data-sal-duration="550" data-sal-delay="200" data-sal-easing="ease-out" >Calculate your price</h2>
      <p data-sal="slide-up" data-sal-duration="550" data-sal-delay="300" data-sal-easing="ease-out" >
        Tell us, how much ads revenue you want to track. Our pricing is based on "Pay as you go" model so you only pay for the ads revenue we track for you.
      </p>
    </div>

    <div className="prizing__slider__wrap" data-sal="slide-up" data-sal-duration="550" data-sal-delay="500" data-sal-easing="ease-out" >

      <div>
        <div className="prizing__values">
          <h3>Monthly ads revenue</h3>
          <h3 className="prizing__value" id="value-spend">${valueSpend}k{valueExt}</h3>
        </div>

        <div>
          <Slider min={1} max={99} type="range" value={state.sliderVal} name="sliderVal"  />
          <input className="prizing__range__input" min={1} max={99} type="range" value={state.sliderVal} name="sliderVal" onChange={ handleChange } />
          </div>
        </div>

        <div className="prizing__final">
          <h3>{dollarSign}{valueFee}</h3>
          <p>Estimated monthly fee</p>
          {valueSpend > 499 &&
            <Button
              {...{
                variant: "blue",
                link: "/demo-call",
                size: "small",
                text: "Demo call",
              }}
            />
          }

          {valueSpend < 499 &&
            <Button
              {...{
                variant: "blue",
                link: "https://app.ecomtrack.io/register",
                size: "small",
                text: "Start tracking",
              }}
            />
          }
        </div>

      </div>

    </div>
  )
};

export default CustomRange;
