import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import "./Accordion.scss"
import arrowDown from "@/img/ico/arrowDown.svg"

interface Accordion {
  className?: string
  children: ReactChild[] | ReactChildren[] | ReactChildren | ReactChildren[]
}

const Accordion = ({
  className,
  children
} : Accordion) => {

  const [isCollapsed, setCollapsed] = useState(false)

  return (

    <div onClick={() => setCollapsed(!isCollapsed)} className={`collapseRow ${isCollapsed ? "" : "closedRow"}`}>

      <div>
        {children}
      </div>

      <img src={arrowDown} />

    </div>

  )
};

export default Accordion;
