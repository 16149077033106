import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import "./FunctionsTable.scss"
import checkTable from "@/img/ico/checkTable.svg"
import checkCross from "@/img/ico/checkCross.svg"

interface FunctionsTable {
  className?: string
  children: ReactChild[] | ReactChildren[] | ReactChildren | ReactChildren[]
}

const FunctionsTable = ({
  className,
  children
} : FunctionsTable) => {

  return (

    <table>
      <tr className="prizing__features__headrow">
        <td>
          <h4>Feature</h4>
        </td>
        <td>
          <h4>Monthly</h4>
        </td>
        <td>
          <h4>Quarterly</h4>
        </td>
        <td>
          <h4 className="prizing__features__headrow__top">Yearly</h4>
        </td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Unlimited team members</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Clients invitation</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>All integrations</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Metrics dashboard</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Advanced reports</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>ecomtrack Academy</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Member support via email</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Onboarding + implementation call (request)</h4></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
        <td><img src={checkTable} /></td>
      </tr>

      <tr className="prizing__features__line">
        <td><h4>Premium Support</h4></td>
        <td><img src={checkCross} /></td>
        <td><img src={checkCross} /></td>
        <td><img src={checkTable} /></td>
      </tr>


      <tr className="prizing__features__line">
        <td><h4> </h4></td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
    </table>

  )
};

export default FunctionsTable;
